<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row">
				<div class="col-xs-2">
					<h1 class="h2 margin-bottom-20">Corrispettivi</h1>
				</div>
				<FeeLogFilters @filter-changed="filterChanged" :default-date-filter = "dateFilter"/>
			</div>
			<FeeLogTable :feeLogs="feeLogs"/>
            <div class="text-center">
                <Pagination v-if="enabledPagination" :page-count="pagesElements" :click-handler="paginateCallback"/>
            </div>
		</div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
	import FeeLogTable from '@/components/FeeLog/FeeLogTable'
    import {getFeeLogs} from "../api"
	import baseMixin from '@/common/baseMixin'
	import FeeLogFilters from '@/components/FeeLog/FeeLogFilters'
    import Pagination from "../components/common/Pagination"



    export default {
        title: "Registro dei Corrispettivi",
        name: "FeeLog",
		mixins: [baseMixin],

        components: {ContainerWithMenu,FeeLogTable,FeeLogFilters, Pagination},
		data: function() {
            return {
                loading: false,
                feeLogs: {},
				dateFilter: "this-month",
                filterEnabled: {
                    document_type: 1,
                },
                enabledPagination: true
            }
        },
        created() {
            let filter = this.Utils.getStartEndDateFromString(this.dateFilter);
            this.filterEnabled.start_date = filter.start_date;
            this.filterEnabled.end_date = filter.end_date;
            this.getFeeLogs(1);
        },
        methods: {
            paginateCallback(pageNum) {
                this.getFeeLogs(pageNum);
            },
            async getFeeLogs(page = 1, filter=this.filterEnabled) {
                this.loading = true;
                try {
                    let feeLogsResponse  = await getFeeLogs(page, filter);
                    this.feeLogs = feeLogsResponse;
                    this.countElements = feeLogsResponse.count;
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
			filterChanged(filter){
				this.filterEnabled = filter;
                this.getFeeLogs(1, filter);

                this.enabledPagination = false;
                this.$nextTick(() => {
                    this.enabledPagination = true;
                });

            }
        }
    };
</script>




