<template>
    <div v-if="feeLogs.data">
        <table class="table table-hover" v-if="feeLogs.data.length > 0">
            <thead>
            <tr>
                <th>Data</th>
                <th>Utente</th>
                <th>Metodo di pagamento</th>
                <th>Tipo</th>
                <th class="text-right">Lordo</th>
                <th class="text-right">Fee</th>
                <th class="text-right">Netto</th>
            </tr>
            </thead>
            <tbody id="reservation-list">
            <FeeLogRow v-for="feeLog in feeLogs.data" :feeLog='feeLog' v-bind:key ='feeLog.id' :fee-log-creator="feeLogs.creator" :fee-log-payment-mode="parseInt(feeLogs.payment_mode)" :fee-log-document-type="parseInt(feeLogs.document_type)" />
            </tbody>
        </table>
        <span v-else class="text-muted">
            Registro dei corrispettivi vuoto
        </span>
    </div>

</template>

<script>
import FeeLogRow from '@/components/FeeLog/FeeLogRow'
    export default {
        name: "FeeLogTable",
        components: {FeeLogRow},
        props: {
            feeLogs: {
                type: Object,
                required: true
            }
        }
    }
</script>
